<template>
  <div>
    <CRow>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            <div class="row">
              <CCol col="6">
                <strong>{{ $lang.role.crud.edit }}</strong>
              </CCol>
            </div>
          </CCardHeader>
          <CCardBody>
            <CAlert
                color="danger"
                closeButton
                :show.sync="dismissCountDown"
                fade
            >
              {{ err_msg }}
            </CAlert>

            <CForm @submit.prevent="onSubmit">
              <div
                  class="form-group"
                  :class="{ 'form-group--error': $v.role.name.$error }"
              >
                <label class="form__label"
                >{{ $lang.role.form.name }}
                  <required_span/>
                </label>
                <input
                    :maxlength="maxlength.name"
                    type="text"
                    :placeholder="$lang.role.form.name"
                    class="form-control"
                    v-model="role.name"
                />

                <small
                    class="error"
                    v-if="$v.role.name.$error && !$v.role.name.required"
                >{{ $lang.role.validation.required.name }}</small
                >
              </div>

              <div class="form-group">
                <label class="form__label">{{ $lang.role.form.permission }}<required_span/></label>
              </div>
              <div id="container"></div>
<!--                <div class="row">-->
<!--                  <div class="col-lg-4">-->
<!--                    <input-->
<!--                        type="checkbox"-->
<!--                        v-model="selectAll"-->
<!--                        @click="select()"-->
<!--                    />-->
<!--                    Select all-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="row role-list">-->
<!--                  <div-->
<!--                      class="col-lg-4"-->
<!--                      v-for="(module, index) in modules"-->
<!--                      :key="index"-->
<!--                      v-if="index !== 'Masters' && index !== 'Masters'"-->
<!--                  >-->
<!--                    <label-->
<!--                        for="perId"-->
<!--                        class="main-label"-->
<!--                        v-if="index !== 'selected' && index !== 'role'"-->
<!--                    >-->
<!--                      <input-->
<!--                          v-if="module.child[0]"-->
<!--                          :value="index"-->
<!--                          @key="index"-->
<!--                          @click="checkAllPermission(module, index)"-->
<!--                          :class="index"-->
<!--                          name="persId"-->
<!--                          :id="'persId' + module.child[0].names"-->
<!--                          type="checkbox"-->
<!--                      />-->
<!--                      &nbsp;<b>{{ index }}</b-->
<!--                    ><br/>-->

<!--                      <hr v-if="index !== 'selected'" align="left"/>-->
<!--                      <template-->
<!--                          v-for="childItem in module.child"-->
<!--                          @key="childItem.permissionId"-->
<!--                      >-->
<!--                        <div class="form-check form-check-inline">-->
<!--                          <input-->
<!--                              :value="childItem.permissionId"-->
<!--                              @key="childItem.permissionId"-->
<!--                              name="perId"-->
<!--                              :id="'perId' + childItem.permissionId"-->
<!--                              @click="checkedPermission(childItem)"-->
<!--                              :class="childItem.names"-->
<!--                              type="checkbox"-->
<!--                              :checked="modules.selected.includes(childItem.permissionId)"-->
<!--                          />-->
<!--                          <label class="form-check-label pl-1 mr-4">-->
<!--                            {{ getCapitalString(childItem.name) }}-->
<!--                          </label>-->
<!--                        </div>-->
<!--                        <br/>-->
<!--                      </template>-->
<!--                    </label>-->
<!--                  </div>-->
<!--                </div>-->

              <CRow>
                <CCol col="6" class="text-left">
                  <CButton
                      type="submit"
                      size="sm"
                      color="primary"
                      :disabled="submitted"
                  >
                    <CIcon name="cil-check-circle"/>
                    {{ $lang.buttons.general.crud.update }}
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import Tree from "@widgetjs/tree";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {modules, role} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {required} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import {Maxlength} from "/src/store/maxlength";
import $ from "jquery";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "RoleEdit",
  mixins: [Mixin],
  components: {
    required_span,
  },
  data() {
    return {
      selectAll: false,
      submitted: false,
      adminId: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      checkedPer: [],
      modules: [],
      collectPermission: [],
      activePage: 1,
      moduleMaster: modules,
      module: role,
      role: {
        name: "",
        permission: "",
      },
      maxlength: {
        name: Maxlength.role.name,
      },
    };
  },

  validations: {
    role: {
      name: {
        required,
      },
    },
  },
  mounted() {
    let self = this;

    //self.data = this.loadData();
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    this.$root.$on("alert", (arg1, arg2) => {
      // this.setAlert(true);
    });
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");

    const id = this.$route.params.id;
    self.dismissCountDown = 0;
    self.editId = id;
    axios
        .get(this.viewUrlApi(this.module, self.editId))
        .then((response) => {
          self.modules = response.data.data.all;
          self.role.name = response.data.data.name;
          new Tree('#container', {
            data: response.data.data.all,
            values: response.data.data.selected,
            // closeDepth: 1,
            beforeLoad: function(rawData) {
              function formatData() {

              }
              return formatData(rawData);
            },
            loaded: function() {
              // this.values = [];
            },
            onChange: function() {
              self.checkedPer = this.values;
            },
          });

          store.commit("showLoader", false); // Loader End
        })
        .catch(function (error) {
          store.commit("showLoader", false); // Loader Off
        });
    self.adminId = localStorage.getItem("userId");
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    // checkedPermission(item) {
    //   const new_permission = item.names;
    //   if (new_permission === "profile-verification" && $(".profile-verification").prop("checked") === true) {
    //     $(".profile-verification").prop("checked", true);
    //     $(".list-social-links").prop("checked", true);
    //     $(".edit-social-links").prop("checked", true);
    //     $(".view-profile-verification").prop("checked", true);
    //     $(".view-profile").prop("checked", true);
    //   } else if (
    //       new_permission === "profile-verification" &&
    //       $(".profile-verification").prop("checked") === false
    //   ) {
    //     $(".profile-verification").prop("checked", false);
    //     $(".list-social-links").prop("checked", false);
    //     $(".edit-social-links").prop("checked", false);
    //     $(".view-profile-verification").prop("checked", false);
    //     $(".view-profile").prop("checked", false);
    //   }
    // },
    //
    // select() {
    //   if (!this.selectAll) {
    //     $("input:checkbox").prop("checked", true);
    //   } else {
    //     $("input:checkbox").prop("checked", false);
    //   }
    // },
    //
    // checkAllPermission(module) {
    //   const new_permission = module.child[0].names;
    //   if ($("#persId" + new_permission).prop("checked") === true) {
    //     let n = new_permission.indexOf("-");
    //     let res = new_permission.substring(n);
    //     if (res === "-profile") {
    //       $(".profile-verification").prop("checked", true);
    //       $(".list-social-links").prop("checked", true);
    //       $(".edit-social-links").prop("checked", true);
    //       $(".view" + res + "-verification").prop("checked", true);
    //       $(".list" + res + "-update-request").prop("checked", true);
    //       $(".view" + res + "-update-request").prop("checked", true);
    //       $(".pending" + res + "-update-request").prop("checked", true);
    //     }
    //     $(".create" + res).prop("checked", true);
    //     $(".edit" + res).prop("checked", true);
    //     $(".delete" + res).prop("checked", true);
    //     $(".view" + res).prop("checked", true);
    //     $(".deactive" + res).prop("checked", true);
    //     $(".verify" + res).prop("checked", true);
    //     $(".list" + res).prop("checked", true);
    //     $(".import" + res).prop("checked", true);
    //   } else {
    //     let n = new_permission.indexOf("-");
    //     let res = new_permission.substring(n);
    //     if (res === "-profile") {
    //       $(".profile-verification").prop("checked", false);
    //       $(".list-social-links").prop("checked", false);
    //       $(".edit-social-links").prop("checked", false);
    //       $(".view" + res + "-verification").prop("checked", false);
    //       $(".list" + res + "-update-request").prop("checked", false);
    //       $(".view" + res + "-update-request").prop("checked", false);
    //       $(".pending" + res + "-update-request").prop("checked", false);
    //     }
    //     $(".create" + res).prop("checked", false);
    //     $(".import" + res).prop("checked", false);
    //     $(".edit" + res).prop("checked", false);
    //     $(".delete" + res).prop("checked", false);
    //     $(".view" + res).prop("checked", false);
    //     $(".deactive" + res).prop("checked", false);
    //     $(".verify" + res).prop("checked", false);
    //     $(".list" + res).prop("checked", false);
    //   }
    // },

    onSubmit() {
      // let imageFile = this.FILE;
      // let size = imageFile.size / maxSize / maxSize
      this.$v.role.$touch();
      if (this.$v.role.$invalid) {
        this.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        this.submitted = true; //Disable Button
        // const checkedPer = [];
        // const pers = document.querySelectorAll('input[name="perId"]');
        // for (let i = 0; i < pers.length; i++) {
        //   if (pers[i].checked) {
        //     // var checkedValue = pers[i].value;
        //     const checkedValue = pers[i].id.replace("perId", "");
        //     checkedPer.push(checkedValue);
        //   }
        // }
        // upload file
        let self = this;
        const postData = {
          name: self.role.name,
          permission_ids: self.checkedPer,
          updated_by: localStorage.getItem("userId"),
        };
        axios
            .post(this.updateUrlweb(this.module, self.editId), postData)
            .then((response) => {
              if (response.data.code === 200) {
                self.err_msg = response.data.message;
                localStorage.setItem("notificationType", "success");
                localStorage.setItem(
                    "notificationMessage",
                    response.data.message
                );
                self.dismissCountDown = 10;
                this.$router.push({name: "Role"});
              } else {
                self.err_msg = response.data.response.message;
                self.dismissCountDown = 10;
                self.submitted = false; //Enable Button
              }
            }).catch(function (error) {
          let data;
          if (error.response.data.error) {
            data = error.response.data.error.toString();
          } else {
            data = error.response.data.message;
          }
          self.err_msg = data;
          self.dismissCountDown = 10;
          self.submitted = false; //Enable Button
        });
      }
    },
    setAlert(value) {
      this.showAlert = value;
    },
    rowClicked(item) {
      this.$router.push({path: `country/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
